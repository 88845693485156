<template>
  <div class="wrap">
    <Header />
    <Main />
    <Footer />
  </div>
</template>

<script>
import Header from "../components/Header";
import Main from "../components/Main.vue";
import Footer from "../components/Footer";
export default {
  components: {
    Header,
    Main,
    Footer
  }
};
</script>
<style scoped>
.wrap{
  height: 100%;
}
</style>
