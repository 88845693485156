<template>
  <main class="main">
    <div class="main-title">
      <div class="logo-area">
        <a href="/" class="link-home"> <img src="../assets/img/logo.png" alt=" logo" class="logo"/></a>
      </div>
      <div class="title-area">
        <h1 class="title">
          CheckTaxi
        </h1>
        <h2 class="subtitle"><img src="../assets/img/subtitleIcon.svg" class="subtitle-icon" alt="point"/>
          <p>Томск и Томская область</p>
        </h2>
      </div>
    </div>
    <div class="main-header">
      <p class="title">Проверка наличия разрешения у легкового такси</p>
    </div>
    <article class="number-form-area">
      <form id="main-number-form" class="number-form"
            :class="{focused: formFocus}"
      >
        <section class="request-area">
          <p class="note">Введите государственный номер автомобиля</p>
          <div class="form-input-container">
            <div class="ng-number ng-number_lg">
              <div class="ng-number_number">
                <div>
                  <input
                      type="text"
                      name="series1"
                      class="main-form-input series1"
                      maxlength="1"
                      placeholder="A"
                      autocomplete="off"
                      :autofocus="true"
                      :value="firstPartOfNumber"
                      @focus="formFocus=true"
                      @blur="formFocus=false"
                      @paste="pasteCarNumber"
                      @input="checkFirstPartOfNumber"
                      @keydown="goToNextInput"
                  >
                </div>
                <div>
                  <input
                      type="text"
                      name="number"
                      class="main-form-input number"
                      maxlength="3"
                      placeholder="777"
                      autocomplete="off"
                      :value="secondPartOfNumber"
                      @focus="formFocus=true"
                      @blur="formFocus=false"
                      @paste="pasteCarNumber"
                      @input="checkSecondPartOfNumber"
                      @keydown="goToNextInput"
                  >
                </div>
                <div>
                  <input
                      type="text"
                      name="series2"
                      class="main-form-input series2"
                      maxlength="2"
                      placeholder="AA"
                      autocomplete="off"
                      :value="thirdPartOfNumber"
                      @focus="formFocus=true"
                      @blur="formFocus=false"
                      @paste="pasteCarNumber"
                      @input="checkThirdPartOfNumber"
                      @keydown="goToNextInput"
                  >
                </div>
              </div>
              <div class="ng-number_region">
                <input
                    type="text"
                    name="region"
                    class="main-form-input region"
                    placeholder="777"
                    maxlength="3"
                    autocomplete="off"
                    :value="fourthPartOfNumber"
                    @focus="formFocus=true"
                    @blur="formFocus=false"
                    @paste="pasteCarNumber"
                    @input="checkFourthPartOfNumber"
                    @keydown="goToNextInput">
                <img src="../assets/img/rus.svg" alt="rus">
              </div>
            </div>
            <div class="ng-notice">
              <div><small>{{ userMessage }}</small></div>
              <div>{{ messageInfo }}</div>
            </div>
          </div>
          <button type="submit" class="ng-search_submit"
                  @click="submitTaxiNumber">
            <span>Проверить</span>
          </button>
        </section>
        <div :loading="isLoadingResponse"></div>
        <section v-if="isResponseReady & stateStatus===0" class="response-area">
          <p class="main-info error-info">
            {{ serverMessage }}
          </p>
        </section>
        <section v-else-if="isResponseReady & stateStatus!==0" class="response-area">
          <p class="main-info">Разрешение на перевозку пассажиров и багажа
            легковым такси у автомобиля с государственным
            регистрационным номером {{ taxiResponseInfo.avto_number || taxiNumber }}
            <span v-if="stateStatus === 3" class="info-result green">
              действительно <span v-if="taxiResponseInfo.date_to">до {{ taxiResponseInfo.date_to }}</span>
            </span>
            <span v-else class="info-result green">
              не найдено
            </span>
          </p>
          <p>-Статус разрешения:
            <span v-if="stateStatus === 3">
              действующее разрешение
            </span>
            <span v-else class="info-result green">
              отсутствует
            </span>

          </p>
          <div v-if="stateStatus === 3 || stateStatus === 2">
            <p v-if="(licenceLostReasons)">-Причина и дата снятия решения досрочно: {{ licenceLostReasons }}</p>
            <p>-Марка, модель автомобиля перевозчика: {{ taxiResponseInfo.vehicle_brand }},
              {{ taxiResponseInfo.avto_model }}</p>
            <p>-Государственный регистрационный знак: {{ taxiResponseInfo.avto_number }}</p>
          </div>
        </section>
      </form>
    </article>
    <article class="transition-button-place">
      <div class="transition-button left"
           :class="{active: isShowedMemo}"
           @click="showMemo"
      >
        <img class="transition-image" src="../assets/img/faq.svg" alt="rus">
        <p><span>Как это работает</span></p>
      </div>
      <div class="transition-button right"
           :class="{active: isShowedComplaintForm}"
      >
        <img class="transition-image" src="../assets/img/complaint.svg" alt="rus">
        <p><span><b>ГОРЯЧАЯ ЛИНИЯ о легковом такси </b><a  style="color: black; text-decoration: none; font-weight: bold;" href="tel:+73822527103">8 (3822) 52-71-03</a></span></p>
      </div>
    </article>
    <TaxiMemo v-if="isShowedMemo"/>
    <ComplaintForm v-if="isShowedComplaintForm" :complaintCarNumber="complaintCarNumber"/>
    <!-- :class="{hidden: !isShowedComplaintForm, showed: isShowedComplaintForm}" -->
  </main>
</template>
<script>
import ComplaintForm from "./ComplaintForm";
import TaxiMemo from "./TaxiMemo";
import taxiCheckService from '../services/taxiCheckService';

export default {
  components: {
    ComplaintForm,
    TaxiMemo
  },
  data() {
    return {
      isShowedComplaintForm: false,
      isShowedMemo: false,

      formFocus: false,
      userMessage: '',
      messageInfo: '',

      firstPartOfNumber: '',
      secondPartOfNumber: '',
      thirdPartOfNumber: '',
      fourthPartOfNumber: '',

      isResponseReady: false,
      isLoadingResponse: false,
      taxiResponseInfo: null,
      stateStatus: null,
      // 0 - ошибка, спам и тд.
      // 1 - сервер работает но данных по номеру нет
      // 2 - разрешения нет, истекло, сдано и тд
      // 3 - разрешение действительно
      responseStatus: null,
      serverMessage: '',

      complaintCarNumber: ''
    }
  },
  computed: {
    taxiNumber() {
      let taxiNumber = this.firstPartOfNumber + this.secondPartOfNumber + this.thirdPartOfNumber + this.fourthPartOfNumber
      return taxiNumber
    },
    mainFormInputs() {
      return document.querySelectorAll('.main-form-input');
    },
    licenceLostReasons() {
      if (this.stateStatus === 2) {
        if (this.taxiResponseInfo.stop_reason || this.taxiResponseInfo.end_reason || this.taxiResponseInfo.renew_reason) {
          let reasons = ""
          if (this.taxiResponseInfo.stop_reason) {
            reasons = reasons + this.taxiResponseInfo.stop_reason + " "
          }
          if (this.taxiResponseInfo.end_reason) {
            reasons = reasons + this.taxiResponseInfo.end_reason + " "
          }
          if (this.taxiResponseInfo.renew_reason) {
            reasons = reasons + this.taxiResponseInfo.renew_reason + " "
          }
          return reasons
        } else {
          return null
        }
      } else {
        return null
      }
    }
  },
  methods: {
    showMemo() {
      this.isShowedMemo = !this.isShowedMemo;
      this.isShowedComplaintForm = false;
    },
    showComplaintForm() {
      if (this.isShowedComplaintForm) {
        this.complaintCarNumber = '';
      }
      this.isShowedComplaintForm = !this.isShowedComplaintForm;
      this.isShowedMemo = false;
    },
    checkTextField(event) {
      const regexText = /([^АВЕКМНОРСТУХABEKMHOPCTYX]+)/;
      event.target.value = event.target.value.toUpperCase();
      if (regexText.test(event.target.value)) {
        event.target.value = event.target.value.replace(/[^АВЕКМНОРСТУХABEKMHOPCTYX]/g, "");
        this.userMessage = 'Используйте русские буквы';
        this.messageInfo = 'A B E K M H O P C T У X';
      } else {
        this.userMessage = '';
        this.messageInfo = '';
      }
      this.latinToCyrillic(event)
    },
    checkNumberField(event) {
      const regexNumber = /([^1234567890]+)/;
      if (regexNumber.test(event.target.value)) {
        event.target.value = event.target.value.replace(/[^1234567890]/g, "");
        this.userMessage = 'Введите цифру';
        // this.messageInfo = 'A B E K M H O P C T У X';
      } else {
        this.userMessage = '';
        this.messageInfo = '';
      }
    },
    latinToCyrillic(event) {
      const cirillicSymbols = ['А', 'В', 'Е', 'К', 'М', 'Н', 'О', 'Р', 'С', 'Т', 'У', 'Х']
      const latinSymbols = ['A', 'B', 'E', 'K', 'M', 'H', 'O', 'P', 'C', 'T', 'Y', 'X']
      latinSymbols.forEach((symbol, index) => {
        let regexSymbol = new RegExp(symbol, 'g')
        event.target.value = event.target.value.replace(regexSymbol, cirillicSymbols[index]);
      })
    },
    checkFirstPartOfNumber(event) {
      this.isResponseReady = false;
      this.checkTextField(event)
      this.firstPartOfNumber = event.target.value
    },
    checkSecondPartOfNumber(event) {
      this.isResponseReady = false;
      this.checkNumberField(event)
      this.secondPartOfNumber = event.target.value
    },
    checkThirdPartOfNumber(event) {
      this.isResponseReady = false;
      this.checkTextField(event)
      this.thirdPartOfNumber = event.target.value
    },
    checkFourthPartOfNumber(event) {
      this.isResponseReady = false;
      this.checkNumberField(event)
      this.fourthPartOfNumber = event.target.value
    },
    goToNextInput(event) {
      let inputLength = Number(event.target.getAttribute('maxlength'));
      let formInputs = this.mainFormInputs;
      let inputClass = event.target.getAttribute('name');
      if (event.keyCode === 8) {
        if (event.target.value.length === 0) {
          formInputs.forEach((input, index) => {
            if (input.getAttribute('name') === inputClass & index !== 0) {
              formInputs[index - 1].focus();
            }
          })
        }
      } else if (!event.ctrlKey) {
        if (event.target.value.length >= inputLength) {
          formInputs.forEach((input, index) => {
            if (input.getAttribute('name') === inputClass & index + 1 < formInputs.length) {
              formInputs[index + 1].focus();
            }
          })
        }
      }
    },
    async recaptcha() {
      // (optional) Wait until recaptcha has been loaded.
      await this.$recaptchaLoaded()

      // Execute reCAPTCHA with action "login".
      const token = await this.$recaptcha('login')
      return token;
      // Do stuff with the received token.
    },

    async submitTaxiNumber(event) {
      event.preventDefault();
      this.serverMessage = "";
      this.stateStatus = null;
      this.isResponseReady = false;
      let recaptchaToken = await this.recaptcha();
      if (this.isNotNumberEmpty()) {
        this.isLoadingResponse = true;
        let request = {
          gosno: this.taxiNumber,
          recaptchaToken: recaptchaToken
        }
        let jsonReq = JSON.stringify(request);

        let result = await taxiCheckService.sendTaxiNumber(jsonReq);
        this.isLoadingResponse = false;
        this.isResponseReady = true;
        this.taxiResponseInfo = result.data;
        this.responseStatus = result.status;
        this.stateCheck();
        this.complaintCarNumber = this.firstPartOfNumber + ' ' + this.secondPartOfNumber + ' ' + this.thirdPartOfNumber + ' ' + this.fourthPartOfNumber;
        console.log(result.data);
      }
    },
    stateCheck() {
      if (this.responseStatus) {
        if (this.responseStatus !== 200) {
          if (this.taxiResponseInfo.message) {
            this.serverMessage = this.taxiResponseInfo.message
          } else {
            this.serverMessage = "Извините, при обработке запроса произошла ошибка. Попробуйте еще раз, пожалуйста, через некоторое время."
          }
          this.stateStatus = 0
        } else if (this.responseStatus === 200) {
          if (this.taxiResponseInfo.status === "not found") {
            if (this.taxiResponseInfo.lic_number) {
              this.stateStatus = 2
            } else {
              this.stateStatus = 1
            }
            this.serverMessage = ""
          } else if (this.taxiResponseInfo.status === "ok") {
            this.stateStatus = 3
            this.serverMessage = ""
          } else if (this.taxiResponseInfo.status === "spam" || this.taxiResponseInfo.status === "error") {
            this.stateStatus = 0
            this.serverMessage = this.taxiResponseInfo.message
          }
        }
      } else {
        this.serverMessage = "Извините, при обработке запроса произошла ошибка. Попробуйте еще раз, пожалуйста, через некоторое время."
        this.stateStatus = 0
      }
    },
    isNotNumberEmpty() {
      if ((!this.firstPartOfNumber) ||
          (!this.secondPartOfNumber) ||
          (!this.thirdPartOfNumber) ||
          (!this.fourthPartOfNumber)) {
        this.userMessage = 'Заполните все поля';
        return false
      } else {
        this.userMessage = '';
        return true
      }
    },
    pasteCarNumber(event) {
      const regexCarNumber = /([АВЕКМНОРСТУХ]{1})([1234567890]{3})([АВЕКМНОРСТУХ]{2})([1234567890]{2,3})/i
      event.preventDefault();

      let pasteData = event.clipboardData.getData('text');
      if (regexCarNumber.test(pasteData)) {
        let partsOfNumber = pasteData.match(regexCarNumber);
        this.firstPartOfNumber = partsOfNumber[1];
        this.secondPartOfNumber = partsOfNumber[2];
        this.thirdPartOfNumber = partsOfNumber[3];
        this.fourthPartOfNumber = partsOfNumber[4];
        this.userMessage = '';
      } else {
        this.userMessage = 'В данное поле можно скопировать только автомобильный номер';
      }
    }
  }
}
</script>
<style lang="scss">
[loading] {
  display: block;
  padding: 50px 0;

  @keyframes spinner {
    to {
      transform: rotate(360deg);
    }
  }

  &:before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    width: 80px;
    height: 80px;
    top: 70%;
    left: 50%;
    margin-left: -40px;
    border-radius: 50%;
    border: 2px solid #ccc;
    border-top-color: #333;
    animation: spinner .6s linear infinite;
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
  }

  & > div {
    display: none;
  }
}

@media (min-width: 720px) {
  [loading] {
    &:before {
      top: 65%;
      margin-top: -40px;
    }
  }
}

.hidden {
  height: 0px;
  // transition: all 0.6s ease-out;
}

.showed {
  transition: all 0.6s ease-out;
}

.transition-button-place {
  display: flex;
  justify-content: center;
  padding: 30px 20px;
  // margin: 0 5vw;
  .transition-image {
    width: 40px;
    height: 40px;
    margin-right: 5px;
  }

  .transition-button {
    display: flex;
    align-items: center;
    cursor: pointer;

    p {
      margin: 0;
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0.02em;
      text-decoration: underline;
      // border-bottom: 1px solid;
      transition: all 0.3s ease-out;
    }
  }

  .transition-button.left {
    margin-right: 1vw;
  }

  .transition-button.right {
    margin-left: 1vw;
  }

  .transition-button.active {
    p {
      color: #FF7058;
    }
  }

  .transition-button:hover {
    p {
      color: #FF7058;
    }
  }
}

@media (min-width: 520px) {
  .transition-button-place {
    .transition-image {
      width: 55px;
      height: 55px;
      margin-right: 10px;
    }

    .transition-button {
      p {
        font-size: 16px;
        line-height: 26px;
      }
    }
  }
}

@media (min-width: 640px) {
  .transition-button-place {
    .transition-image {
      width: 70px;
      height: 70px;
    }

    .transition-button {
      p {
        font-size: 18px;
        line-height: 26px;
      }
    }
  }

}

@media (min-width: 768px) {
  .transition-button-place {
    display: flex;
    justify-content: center;
    padding: 50px 10px;

    .transition-button.left {
      margin-right: 10px;
    }

    .transition-button.right {
      margin-left: 10px;
    }
  }
}

@media (min-width: 1333px) {

}

@media (min-width: 1442px) {
  .transition-button-place {
    .transition-image {
      width: 55px;
      height: 55px;
    }

    .transition-button {
      p {
        font-size: 22px;
        line-height: 26px;
      }
    }
  }
}
</style>
