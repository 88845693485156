import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import { VueReCaptcha } from "vue-recaptcha-v3";
import './assets/scss/style.scss';
const VueInputMask = require('vue-inputmask').default;

Vue.use(VueInputMask);
Vue.use(VueReCaptcha, { siteKey: process.env.VUE_APP_SITE_KEY });

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
