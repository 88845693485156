<template>
  <div id="app" v-cloak>
    <Home />
  </div>
</template>

<script>
import Home from './views/Home.vue';

export default {
  name: "App",
  components: {
    Home
  }
};
</script>

<style lang="scss">
</style>
