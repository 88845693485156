import api from './index';


/*const dataFixtureFound = {
  status: 200,
  data: {
    "lic_number": "70\u041b\u0422000389",
    "status": "ok",
    "date_from": "28.11.2023",
    "vehicle_brand": "марка авто",
    "avto_model": "\u0420\u0410\u041f\u0418\u0414",
    "avto_number": "\u041d448\u0415\u041d70",
    "inn": "",
    "ogrn": ""
  }
}*/

/*const dataFixtureNotFound = {
    status: 200,
    data: {
        "lic_number": "70\u041b\u0422000389",
        "status": "not_found",
        "date_from": "28.11.2023",
        "vehicle_brand": "марка авто",
        "avto_model": "\u0420\u0410\u041f\u0418\u0414",
        "avto_number": "\u041d448\u0415\u041d70",
        "inn": "",
        "ogrn": ""
    }

}*/

function sendTaxiNumber(number) {
  /*console.log(number)
  return dataFixtureNotFound;*/
  return api().post('/check-taxi/by-gos-number', number);
}
function createComplaint(complaint){
  return api().post('/complaints/create', complaint);
}

export default {
  sendTaxiNumber,
  createComplaint
};
