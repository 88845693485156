<template>
    <article class="complaint-form-area">
        <form id="complant-form" class="complaint-form">
            <div class="input-stack complaint-element">
                <p class="input-title">Введите государственный номер автомобиля</p>
                <input type="text"
                    name="car-number" 
                    class="complaint-input car-number-input" 
                    value="" 
                    placeholder="А 777 АА 777"
                    v-mask="{
                        mask: 'a 999 aa 999', 
                        placeholder: '',
                        greedy: true,
                        definitions: {
                            'a': {
                                validator: '[АВЕКМНОРСТУХавекмнорстуABEKMHOPCTYXabekmhopctyx]',
                            }
                        }
                    }"
                    v-model="carNumber" 
                    autocomplete="off">
                <small class="error-input">{{carNumberNotice}}</small>
            </div>
            <div class="input-stack complaint-element">
                <p class="input-title">Введите агрегатора (службу заказа такси)</p>
                <input type="text"
                    name="aggregator" 
                    class="complaint-input" 
                    value="" 
                    placeholder="Агрегатор такси"
                    v-model="aggregator" 
                    autocomplete="off">
                <small class="error-input">{{aggregatorNotice}}</small>
            </div>
            <div class="input-stack complaint-element">
                <p class="input-title">Марка, модель транспортного средства</p>
                <input type="text"
                    name="car-model" 
                    class="complaint-input" 
                    value="" 
                    placeholder="Марка, модель транспортного средства"
                    v-model="carModel" 
                    autocomplete="off">
                <small class="error-input">{{carModelNotice}}</small>
            </div>
            <small class="error-input complaint-element" :class="{succes: succesMessage}">{{requestMessage}}</small>
             <button type="submit" class="complaint-submit complaint-element"
                @click="submitComplaint"
             >
                <span>Сообщить</span>
            </button>
        </form>
    </article>
</template>
<script>
const EMPTY_INPUT_ERROR = 'Пожалуйста заполните поле'

import taxiCheckService from '../services/taxiCheckService';

export default {
    props: ['complaintCarNumber'],
    data(){
        return {
            carNumberNotice: '',
            aggregatorNotice: '',
            carModelNotice: '',
            requestMessage: '',

            carNumber: this.complaintCarNumber,
            aggregator: '',
            carModel: '',

            succesMessage: false
        }
    },
    methods: {
        async recaptcha() {
            // (optional) Wait until recaptcha has been loaded.
            await this.$recaptchaLoaded()

            // Execute reCAPTCHA with action "login".
            const token = await this.$recaptcha('login')
            return token;
            // Do stuff with the received token.
        },
        async submitComplaint(event){
            event.preventDefault();
            let recaptchaToken = await this.recaptcha();
            if(this.validateComplaint()){
                this.latinToCyrillic()
                let gosNumber = this.removeSpaces(this.carNumber)
                let request = {
                        gosno: gosNumber,
                        recaptchaToken: recaptchaToken,
                        taxi_agregator: this.aggregator,
                        car_model: this.carModel,
                    }
                console.log(request)
                let jsonReq = JSON.stringify(request);
                try{
                    let result = await taxiCheckService.createComplaint(jsonReq);
                    if(result.status === 200){
                        this.requestMessage = "Жалоба отправлена. Мы рассмотрим ее в ближайшее время";
                        this.succesMessage = true;
                        setTimeout(()=>{
                            this.requestMessage = '';
                            this.succesMessage = false;
                        }, 4000)
                        this.carNumber = '';
                        this.aggregator = '';
                        this.carModel = '';
                    } else if(result.status === 500){
                        this.requestMessage = "Ошибка сервера. Пожалуйста попробуйте отправить заявку позже";
                        setTimeout(()=>{
                            this.requestMessage = '';
                        }, 4000)
                    } else if(result.status === 422){
                        this.requestMessage = "Ошибка валидации. Пожалуйста попробуйте отправить заявку позже";
                        setTimeout(()=>{
                            this.requestMessage = '';
                        }, 4000)
                    } else {
                        this.requestMessage = "Пожалуйста попробуйте отправить заявку позже";
                        setTimeout(()=>{
                            this.requestMessage = '';
                        }, 4000)
                    }
                } catch {
                    this.requestMessage = "Пожалуйста попробуйте отправить заявку позже";
                    setTimeout(()=>{
                        this.requestMessage = '';
                    }, 4000)
                }
            } else {
                console.log('error');
            }
        },
        removeSpaces(carNumber){
            carNumber.replace(/\s/g, '');
            return carNumber.toUpperCase();
        },
        validateComplaint(){
            if(!this.carNumber){
                this.carNumberNotice = EMPTY_INPUT_ERROR
                return false;
            } else {
                this.carNumberNotice = '';
            }
            if(!this.aggregator){
                this.aggregatorNotice = EMPTY_INPUT_ERROR;
                return false;
            } else {
                this.aggregatorNotice = '';
            }
            // if(!this.carModel){
            //     this.carModelNotice = EMPTY_INPUT_ERROR;
            //     return false;
            // } else {
            //     this.carModelNotice = '';
            // }
            this.carNumberNotice = ''
            this.aggregatorNotice = '';
            this.carModelNotice = '';
            this.requestMessage = '';
            return true;
        },
        latinToCyrillic(){
            const cirillicSymbols = ['А','В','Е','К','М','Н','О','Р','С','Т','У','Х']
            const latinSymbols = ['A','B','E','K','M','H','O','P','C','T','Y','X']
            latinSymbols.forEach((symbol, index)=>{
                let regexSymbol = new RegExp(symbol, 'g')
                this.carNumber = this.carNumber.replace(regexSymbol, cirillicSymbols[index]);
            })
        },
    }
}
</script>
<style lang="scss">
.complaint-form-area{
    display: flex;
    justify-content: center;
    .complaint-form{
        display: flex;
        flex-direction: column;
        width: 80%;
        max-width: 400px;
        height: 100%;
        position: relative;
        .input-stack{
            margin-top: 9px;
            // min-height: 90px;
            position: relative;
            display: flex;
            flex-direction: column;
            .input-title{
                font-size: 16px;
                margin: 1px 0;
            }
            .complaint-input{
                width: 100%;
                border: 1px solid #ccc;
                background-color: #ebf3f7;
                height: 40px;
                box-sizing: border-box;
                border-radius: 4px;
                padding: 0 10px;
            }
            .car-number-input{
               max-width: 120px; 
            }
            .error-input{
                min-height: 15px;
                color: #FF7058;
                font-size: 13px;
            }
        }
        .error-input{
            min-height: 15px;
            color: #FF7058;
            font-size: 13px;
        }
        .succes{
            color: #6eb90c;
        }
        .checkbox-stack{
            display: flex;
            align-items: center;
            margin: 0;
            margin-bottom: 15px;
            label{
              cursor: pointer;  
              font-size: 14px;
            }
            .complaint-checkbox{
                cursor: pointer;
            }
        }
        .complaint-submit{
            align-self: center;
            color: #fff;
            background:#4898ce;
            min-height: 40px;
            width: 110px;
            max-width: 120px;
            border-radius: 8px;
            padding: 0;
            justify-content: center;
            align-items: center;
            box-sizing: border-box;
            border: 0;
            font-size: 16px;
            line-height: 24px;
            cursor: pointer;
            transition: opacity 0.3s ease-out;
            display: flex;
            margin-top: 15px;
            transition: all 0.3s ease-out;
        }
        .complaint-submit:hover{
            background: #52b1f1;
        }
    }
}
.hidden{
    .complaint-form{
        transition: all 0.3s ease-out;
        opacity: 0;
        .complaint-element{
            display: none;
        }
    }
}
.complaint-form-area.showed{
    height: 435px;
}
.showed{
  .complaint-form{
        transition: all 0.9s ease-out;
        opacity: 1;
    }
}
</style>
