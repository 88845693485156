<template>
  <footer class="footer">
    <div class="footer-container">
      <div class="footer-left">
        <div class="logo-area">
          <a href="https://kl.tomsk.gov.ru/" class="link-home"> <img src="../assets/img/logoTomsk.png" alt=" logo" class="logo"/></a>
        </div>
        <div class="footer-info">
          <!-- <h2 class="main-info">Контактная информация</h2> -->
          <p>Администрация Томской области</p>
          <p>Департамент лицензирования и регионального государственного контроля Томской области</p>
        </div>
      </div>
      <div class="footer-right">
        <p><span><a href="https://yandex.ru/maps/67/tomsk/?ll=84.957386%2C56.47221&mode=search&oid=233282452914&ol=biz&source=wizgeo&utm_medium=maps-desktop&utm_source=serp&z=17.15">
            634029, Томская область, г. Томск, ул. Белинского, 15а, 3 этаж
          </a>
          </span>
        </p>
        <p>Электронная почта:<span><a href="mailto:info@palata.tomsk.ru">info@palata.tomsk.ru</a></span></p>
       <p>Тел: <span><a href="tel:+7(3822)528-970">+7(3822) 528-970</a>, Факс: <a href="tel:+7(3822) 532-085">+7(3822) 532-085</a></span></p>
      </div>
    </div>
  </footer>
</template>
<script>

export default {
  
}
</script>
